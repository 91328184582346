export const Desjardins =
    <svg svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" > <path d="M472.9,254.3c0,40.8-0.1,81.6,0.1,122.4c0,4.1-1.2,6.3-4.8,8.3c-46.4,26.6-92.8,53.4-139.1,80.2
c-23.9,13.8-47.8,27.7-71.8,41.4c-1.5,0.9-4.5,0.7-6.1-0.2c-45.8-26.1-91.4-52.3-137.1-78.5c-25.2-14.5-50.3-29.1-75.6-43.4
c-3.6-2-4.4-4.3-4.3-8c0.1-41,0.1-82,0.1-123c0-40.3,0.1-80.6-0.1-120.9c0-4.5,1.5-7,5.3-9.2C74,104,108.5,84.1,143,64.3
C178.6,43.9,214.2,23.5,249.8,3c3.3-1.9,6-1.8,9.2,0.1C300,26.8,341,50.5,382,74.2c29,16.7,57.9,33.5,87,50.1c3.3,1.9,4,4.1,4,7.6
C472.9,172.7,472.9,213.5,472.9,254.3z M94.5,255.3c0,28.6,0.1,57.3-0.1,85.9c0,5,1.8,7.5,5.9,9.8c21.2,11.9,42.3,24.1,63.3,36.2
c28.9,16.6,57.7,33.3,86.6,49.7c2.1,1.2,5.9,1.1,8.1,0c7.1-3.5,13.8-7.7,20.7-11.7c42.9-25,85.8-50,128.8-74.8
c3.4-2,4.7-4.1,4.7-7.9c-0.1-58.3-0.1-116.6,0-175c0-3.7-1.4-5.8-4.5-7.5c-12.4-7-24.8-14.1-37.1-21.3
c-37.6-21.8-75.3-43.6-112.8-65.6c-2.8-1.7-5-1.6-7.7,0c-12.5,7.3-25,14.5-37.5,21.7c-37.9,21.8-75.7,43.6-113.7,65.2
c-3.6,2-4.9,4.3-4.8,8.3C94.6,197.4,94.5,226.4,94.5,255.3z"/>
    </svg >

export const Algego =
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M254,0c1.3,0,2.7,0,4,0c0.5,0.3,1.1,0.3,1.6,0.3c3.4,0,6.9,0.1,10.3,0.4c2.4,0.2,4.9,0.3,7.3,0.4c0.2,0,0.3,0,0.5,0.1
  c1.7,0.2,3.4,0.3,5.1,0.5c1.4,0.1,2.9,0.3,4.3,0.5c2.1,0.3,4.2,0.6,6.3,0.9c3.3,0.5,6.6,1,9.9,1.6c6.1,1.1,12.1,2.5,18,4
  c19.7,5.1,38.5,12.5,56.4,22.3c34.3,18.6,63,43.8,85.9,75.4c16,22.1,28.1,46.1,36.4,72.1c2.6,8.3,4.9,16.7,6.6,25.3
  c0.7,3.5,1.4,7.1,2,10.6c0.4,2.3,0.7,4.6,1,6.9c0.4,2.6,0.7,5.2,0.9,7.8c0.1,1.5,0.3,3,0.4,4.5c0.2,2.4,0.5,4.9,0.5,7.3
  c0,2.3,0.1,4.6,0.1,6.8c0,0.6,0.1,1.2,0.3,1.8c0,4.4,0,8.9,0,13.3c-0.1,0.6-0.3,1.2-0.3,1.8c0,1.3-0.1,2.7-0.1,4
  c0,2.3-0.1,4.7-0.3,7c-0.3,3.5-0.6,7-1,10.4c-0.4,3.6-0.9,7.3-1.5,10.9c-0.7,4.7-1.6,9.4-2.7,14.1c-2.6,11.7-5.9,23.2-10.1,34.5
  c-8.1,21.8-19,42.2-32.8,61c-26.6,36.4-60.5,64-101.5,82.8c-13.3,6.1-27,10.9-41.1,14.5c-5.1,1.3-10.2,2.5-15.4,3.5
  c-2.8,0.6-5.7,1-8.5,1.5c-2.5,0.4-4.9,0.8-7.4,1.1c-2.8,0.4-5.6,0.7-8.4,0.9c-1.5,0.1-3,0.3-4.5,0.4c-3.5,0.3-7,0.5-10.5,0.6
  c-1.1,0-2.1,0.1-3.2,0.1c-0.4,0-0.8,0-1.1,0.2c-3.6,0-7.1,0-10.7,0c-0.3-0.1-0.5-0.2-0.8-0.2c-0.7-0.1-1.3-0.1-2-0.1
  c-3.4,0-6.8-0.3-10.2-0.5c-0.3,0-0.6,0-0.8-0.1c-1.9-0.2-3.9-0.3-5.8-0.5c-1.5-0.1-3-0.3-4.5-0.4c-2.9-0.4-5.8-0.8-8.7-1.2
  c-3.8-0.5-7.7-1.2-11.5-2c-6.9-1.3-13.7-3-20.4-4.9c-23.3-6.6-45.2-16.4-65.7-29.2c-44.8-28.1-80.8-70-101.5-120.9
  c-4.8-12-8.7-24.2-11.7-36.8c-1.1-4.7-2.1-9.4-2.9-14.2c-0.4-2.3-0.8-4.7-1.1-7.1c-0.3-2-0.6-4-0.8-5.9c-0.2-1.3-0.3-2.6-0.5-4
  c-0.2-1.5-0.3-3.1-0.4-4.6c-0.2-1.9-0.4-3.9-0.5-5.8c-0.2-3.5-0.5-7-0.5-10.5c0-0.5,0-1-0.1-1.5c0-0.4-0.1-0.9-0.2-1.3
  c0-0.3-0.2-0.5-0.4-0.7c0-2.3,0-4.7,0-7c0.4-0.4,0.5-1,0.5-1.6c0.1-0.8,0.1-1.6,0.1-2.3c0-3.4,0.3-6.9,0.5-10.3
  c0.1-1.6,0.2-3.2,0.4-4.8c0.3-2.7,0.5-5.3,0.9-7.9c0.3-2.9,0.7-5.7,1.1-8.6c0.7-4.6,1.5-9.2,2.5-13.8c1.7-8,3.8-16,6.2-23.8
  c9.2-29.3,23.3-56.1,42.2-80.4c0.9-1.2,1.8-2.4,2.7-3.6c0.6-0.8,1.3-1.7,2-2.5C64,87.4,70,83.2,76.9,80.5c4.4-1.7,8.9-2.7,13.6-3.1
  c2.7-0.2,5.4-0.2,8.2,0.2c2.3,0.3,4.5,0.6,6.7,1.2c14.3,3.8,25.4,14,30.7,26.5c2.4,5.7,3.6,11.5,3.6,17.7c0,3.6-0.4,7.2-1.2,10.7
  c-1.6,6.6-4.5,12.6-8.7,18c-0.6,0.8-1.3,1.6-1.9,2.3c-12.3,15.6-21.6,32.8-27.7,51.7c-1.9,5.9-3.5,11.9-4.7,18
  c-0.7,3.7-1.4,7.4-1.8,11.2c-0.4,2.8-0.6,5.5-0.8,8.3c-0.3,3.2-0.4,6.3-0.5,9.5c-0.2,5.3,0.1,10.7,0.5,16c0.2,3.2,0.6,6.4,1,9.6
  c0.5,3.6,1.1,7.1,1.8,10.7c1.8,8.8,4.3,17.3,7.5,25.7c13.4,35,39.4,66.1,75,85.5c12.3,6.7,25.3,11.7,38.9,15.1
  c3.7,0.9,7.5,1.7,11.2,2.3c2.6,0.4,5.3,0.8,7.9,1.2c2.9,0.4,5.7,0.6,8.6,0.8c4.4,0.3,8.9,0.5,13.3,0.4c1.4,0,2.9,0,4.3-0.1
  c2.5-0.2,5-0.2,7.5-0.5c2.6-0.2,5.2-0.5,7.8-0.8c3.1-0.4,6.3-0.9,9.4-1.5c5.8-1.1,11.5-2.5,17.1-4.2c19.9-6.1,38-15.6,54.2-28.6
  c24.3-19.5,41.7-43.9,52.3-73.2c2.6-7.2,4.6-14.5,6.1-21.9c0.8-4,1.5-8,2-12c0.3-2.8,0.6-5.5,0.8-8.3c0.2-3,0.4-6.1,0.5-9.2
  c0.2-5.5-0.1-11-0.5-16.5c-0.2-3.2-0.6-6.4-1-9.6c-0.5-3.9-1.2-7.7-2-11.5c-1.9-9.3-4.7-18.4-8.3-27.2c-8.4-20.8-20.6-39.2-36.5-55
  c-16-16-34.6-28.1-55.6-36.5c-8.6-3.4-17.4-6-26.4-7.9c-2.7-0.6-5.4-1.1-8.2-1.5c-1.8-0.3-3.5-0.5-5.3-0.7c-1.4-0.2-2.9-0.3-4.3-0.4
  c-2.7-0.3-5.4-0.5-8.1-0.6c-3.1-0.1-6.1-0.2-9.2-0.2c-3.6-0.1-7.1-0.6-10.5-1.5c-18.5-5-32.6-21.3-33.9-41.8
  c-0.3-4.2,0.1-8.4,1-12.6c2.9-12.5,9.9-22.2,20.7-29.1c4.8-3,10-5,15.6-6.1c1.6-0.3,3.3-0.6,4.9-0.7C253.1,0.2,253.6,0.2,254,0z"/>
    </svg>

export const Images = {
    ArrowWhite: require('../images/arrow-white.png'),
    Envelop: require('../images/envelope.png'),
    AlgegoLogo: require('../images/Logo.png'),
    EnvelopGray: require('../images/envelope-gray.png'),
    ArrowGray: require('../images/arrow-gray.png'),
    tuile2014: require('../images/Tuile_2014.png'),
    tuile2017: require('../images/Tuile_2017.png'),
    tuile2018_1: require('../images/Tuile_2018-01.png'),
    tuile2018_2: require('../images/Tuile_2018-02.png'),
    tuile2019: require('../images/Tuile_2019.png'),
    tuile2022: require('../images/Tuile_2022.png')
}
